<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin: 0; padding-left: 10px">NeXa</h4>
        <span class="divider"></span>
        <span class="breadcrumb-text">Use Cases / Build an Argument</span>
      </div>
      <div>
        <button @click="toggleSidebar" class="open_sidebar me-2">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 mt-3 p-0">
        <div
          class="d-flex justify-content-end cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 0 0 8px">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5 p-0" style="max-height: fit-content">
        <section class="uploads mt-3">
          <div class="mb-2">
            <h6 style="color: #0e4485 !important">Build an Argument</h6>
            <span class="tabs-description">
              Input your legal question or issue, and Nexa will help you
              construct a detailed argument using case law, statutes, and legal
              reasoning.
            </span>
          </div>
          <div class="input-group">
            <label for="legalQuery" class="visually-hidden"
              >Enter your legal query or task here</label
            >
            <textarea
              v-model="legalQuery"
              name="legalQuery"
              id="legalQuery"
              class="input-field custom-placeholder"
              maxlength="3000"
              placeholder="Enter your legal query or task here"
              rows="2"
              autocomplete="off"
            ></textarea>
          </div>

          <div
            v-if="files.length > 0 && files[2].length > 0"
            style="background: var(--Neutral-Interface-Grey, #fafbfc)"
          >
            <b-row class="d-flex flex-wrap">
              <b-col
                v-for="(file, index) in displayedFiles"
                :key="index"
                cols="4"
                class="d-flex align-items-center mt-2 mb-2"
              >
                <div class="d-flex align-items-center ms-4">
                  <span class="material-symbols-rounded badge-icon me-2">
                    {{ file.name.endsWith(".zip") ? "folder" : "description" }}
                  </span>
                  <p class="badge-title" style="margin: 0">
                    {{ truncateFileName(file.name) }}
                  </p>
                </div>
                <div class="ms-auto d-flex align-items-center me-4">
                  <span class="badge-size mb-0 me-2"
                    >{{ (file.file.size / (1024 * 1024)).toFixed(2) }} MB</span
                  >
                  <template v-if="file.uploading">
                    <span>
                      <b-spinner
                        small
                        style="width: 1.15rem; height: 1.15rem"
                        variant="primary"
                        label="Uploading"
                      ></b-spinner>
                    </span>
                  </template>
                  <template v-else>
                    <span
                      class="material-symbols-rounded close-icon"
                      @click="removeFile(index)"
                      >close</span
                    >
                  </template>
                </div>
              </b-col>
            </b-row>

            <div class="d-flex justify-content-center align-items-center">
              <button
                v-if="files[2].length > maxFilesToShow"
                type="button"
                @click="toggleShowAll"
                class="d-flex align-items-center m-3"
                style="color: #1890ff"
              >
                <span class="material-symbols-rounded icon">
                  {{
                    showAll
                      ? "keyboard_double_arrow_up"
                      : "keyboard_double_arrow_down"
                  }}
                </span>
                {{ showAll ? "Show Less" : "Show More" }}
              </button>
            </div>
          </div>

          <b-tooltip target="tooltip_attach" triggers="hover">
            Upload any documents and let our AI show you what it can do!
          </b-tooltip>
          <footer class="search-bar-footer">
            <div class="d-flex ms-1">
              <button
                class="attach-button d-flex align-items-center"
                id="tooltip_attach"
                type="button"
                @click="showAttachModal"
                :style="{
                  color:
                    files.length && files[2].length > 0 ? 'var(--primary)' : '',
                }"
              >
                <span
                  class="material-symbols-rounded icon-small me-1"
                  :style="{
                    color:
                      files.length && files[2].length > 0
                        ? 'var(--primary)'
                        : '',
                  }"
                  >attach_file</span
                >
                Attach
              </button>
              <button
                class="country-button d-flex align-items-center ms-2"
                type="button"
                @click="toggleCountry"
                :style="{ color: selectedCountry ? 'var(--primary)' : '' }"
              >
                <span
                  class="material-symbols-rounded icon-small me-1"
                  :style="{ color: selectedCountry ? 'var(--primary)' : '' }"
                  >arrow_drop_down</span
                >
                <img
                  v-if="selectedCountry"
                  :src="countryImages[selectedCountry]"
                  alt="Country Flag"
                  class="country-flag"
                />
                {{ selectedCountry ? selectedCountry : "Country" }}
              </button>
            </div>
            <div>
              <span class="char-count">{{ charCount }} / 3000</span>
            </div>
          </footer>
          <div
            v-if="isCountry"
            class="country-option"
            v-clickaway="toggleCountry"
          >
            <ul>
              <li
                v-for="(flag, countryCode) in countryImages"
                :key="countryCode"
                @click="selectCountry(countryCode)"
                class="countryOption"
                style="z-index: 5"
              >
                <img :src="flag" alt="Country Flag" class="country-flag" />
                <span>{{ countryCode }}</span>
              </li>
            </ul>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <button
              class="upload cursor-pointer"
              :disabled="!legalQuery.length || !selectedCountry || isSubmitting"
              @click="submitQuery"
            >
              <span class="material-symbols-rounded icon" v-if="!isSubmitting"
                >arrow_forward</span
              >
              Next
              <b-spinner
                v-if="isSubmitting"
                variant="primary"
                style="scale: 0.75"
                class="material-symbols-rounded"
              />
            </button>
          </div>
          <b-modal id="attachfile" hide-footer hide-header size="">
            <div class="d-flex justify-content-between">
              <div>
                <h5>Upload Files</h5>
              </div>
              <div>
                <span
                  class="material-symbols-rounded close-icon"
                  @click="hideAttachModal"
                  >close</span
                >
              </div>
            </div>
            <div>
              <FileInput
                style="flex-grow: 1"
                :accept="['pdf', 'docx', 'png', 'jpg', 'jpeg']"
                @input-files="$set(files, 0, $event)"
              />
              <!-- <p class="mt-2" style="font-size:11px;font-weight: 400;">Your files will be securely stored in NeXa,
                  accessible only to you and your workspace admins, and you can delete them at any time.</p> -->
              <div class="mt-2 d-flex">
                <span class="material-symbols-rounded mt-1 help-icon"
                  >help</span
                >
                <p class="help-description">
                  If you have multiple files to upload, we recommend zipping
                  them into one file to make the process quicker and easier.
                </p>
              </div>
              <FileProcess
                style="width: 100%; max-height: 200px; overflow-y: auto"
                :accept="['pdf', 'docx', 'png', 'jpg', 'jpeg']"
                :files="files[0]"
                @output-files="$set(files, 1, $event)"
              />
            </div>
            <footer>
              <div class="d-flex justify-content-end">
                <div class="upload cursor-pointer" @click="uploadFile">
                  <span class="material-symbols-rounded icon">upload</span>
                  Upload
                </div>
              </div>
            </footer>
          </b-modal>
        </section>
      </div>
      <div class="col-md-1"></div>
    </div>
    <sidebar
      v-clickaway="toggleSidebar"
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
    ></sidebar>
  </div>
</template>
<script>
/* eslint-disable */
import countryImages from "./../../../assets/country/index";
import FileInput from "./../../../components/input/FileInput.vue";
import FileProcess from "./../../../components/input/FileProcess.vue";
import sidebar from "../sidebar.vue";
import usecase from "@/store/usecase.js";

export default {
  components: {
    FileInput,
    FileProcess,
    sidebar,
  },
  data() {
    return {
      loading: true,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCountry: false,
      countryImages,
      files: [[], [], []],
      legalQuery: "",
      selectedCountry: "",
      maxFilesToShow: 9,
      showAll: false,
      isSubmitting: false,
    };
  },
  computed: {
    charCount() {
      return this.legalQuery.length;
    },
    displayedFiles() {
      return this.showAll
        ? this.files[2]
        : this.files[2].slice(0, this.maxFilesToShow);
    },
  },
  methods: {
    truncateFileName(name) {
      return name.length > 17 ? name.substring(0, 19) + "..." : name;
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
      this.$router.push({ name: "ExpertMode" });
    },
    toggleCountry() {
      this.isCountry = !this.isCountry;
    },
    selectCountry(countryCode) {
      this.selectedCountry = countryCode;
      this.isCountry = false;
    },
    showAttachModal() {
      this.$bvModal.show("attachfile");
    },
    hideAttachModal() {
      this.$bvModal.hide("attachfile");
    },
    uploadFile() {
      this.$set(this.files, 2, this.files[1]);
      this.$set(this.files, 0, []);
      this.$set(this.files, 1, []);

      this.displayedFiles.forEach((file) => {
        this.$set(file, "uploading", true);
        const uploadTime = Math.min(
          10000,
          Math.max(1000, (file.file.size / (1024 * 1024)) * 1000)
        ); // Scale upload time

        setTimeout(() => {
          this.$set(file, "uploading", false);
        }, uploadTime + 1000);
      });
      this.hideAttachModal();
    },
    removeFile(index) {
      this.files[2].splice(index, 1);
    },
    async submitQuery() {
      this.isSubmitting = true;
      try {
        this.$validator.validateAll();
        const countryMapping = {
          AUS: "Australia",
          USA: "USA",
          CAN: "Canada",
          NZ: "New Zealand",
          SG: "Singapore",
          MY: "Malaysia",
          UK: "United Kingdom",
        };
        const jurisdiction = {};
        const country = countryMapping[this.selectedCountry];
        jurisdiction[country] = ["all"];

        const locations = localStorage.getItem("locations")
          ? JSON.parse(localStorage.getItem("locations"))
          : [];
        const foundLocation = locations.find((x) => x.title === country);

        const formData = new FormData();
        formData.append("language", "en");
        formData.append("location_id", foundLocation.id);
        formData.append("question", this.legalQuery);
        formData.append("type", "build_argument");
        formData.append("jurisdiction", JSON.stringify(jurisdiction));
        if (this.files[2] && this.files[2].length > 0) {
          this.files[2].forEach((file, index) => {
            formData.append(`question_doc[${index}]`, file.file);
            console.log("FILE NAM", file);
          });
        }

        for (let pair of formData.entries()) {
          console.log(`${pair[0]}: ${pair[1]}`);
        }

        const response = await usecase.CreateUsecase(formData);
        if (response.data) {
          this.$toast.success("Successful");
          this.$router.push({
            name: "BuildArgumentResult",
            params: { id: response.data.data.id },
          });
        }
      } catch (error) {
        this.isSubmitting = false;
        this.$toast.error("Failed to generate deep research");
      }
    },
  },
};
</script>

<style scoped>
.overlay-container {
  width: 25%;
  position: absolute;
  top: 8.7vh;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.help-icon {
  font-size: 18px;
  color: #86888d;
}

.help-description {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  padding-left: 5px;
  color: #86888d !important;
}

.page-content {
  padding: 0px;
  overflow-x: hidden;
}

.file-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 16px 20px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
}
.breadcrumb-text {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.open_sidebar {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
}

.open_sidebar span {
  color: #fff;
  display: flex;
  /* Ensure icon is aligned vertically */
  align-items: center;
  /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0;
  /* Remove any default margin to align the text properly */
  line-height: 1;
  /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center;
  /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}

.col-md-10 {
  overflow-y: auto;
  /* Allows vertical scrolling if content exceeds the height */
  max-height: 100vh;
  /* Ensure it doesn’t exceed the viewport height */
}

.uploads {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(170, 171, 175, 0.6);
}
.tabs-description {
  color: var(--Neutral-Dark-Grey, #86888d);

  /* Paragraph */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.search-bar-footer {
  border-top: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-Light-Grey, #f2f3f3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  border-radius: 0px 0px 8px 8px;
}

.search-bar {
  border-radius: 8px;
  margin-top: 24px;
  width: 100%;
}

.input-group {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  border-radius: 8px 8px 0px 0px;
}

.file-input-group {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  border-radius: 8px 8px 0px 0px;
}

.file-display {
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontally center items */
  justify-content: center; /* Vertically center items */
}

.input-field {
  flex: 1;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  color: var(--Neutral-Black, #383a3e);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  outline: none;
  resize: none;
}

.custom-placeholder::placeholder {
  color: var(--Neutral-Mid-Grey, #d1d2d5);

  /* Subtext */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}

.upload-button {
  border-radius: 4px;
  overflow: hidden;
  padding: 4px;
}

.upload-button img {
  width: 20px;
  object-fit: contain;
}

.attach-button,
.country-button {
  color: #86888d;
  font-size: 14px;
  font-weight: 500;
}

.attach-button:hover,
.country-button:hover {
  color: var(--primary);
  > span {
    color: var(--primary);
  }
}

.char-count {
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.icon-small {
  color: #86888d;
  margin: auto;
  font-size: 18px;
}

.upload {
  background: var(--primary);
  border-radius: 6px;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
}

.upload:disabled {
  background: #0e44851a;
  color: var(--mid-grey);
  pointer-events: none;
}

.country-option {
  margin-left: 7vw;
  width: 100px !important;
  background: #fafbfc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: absolute;
  z-index: 1000; /* Adjust this value as needed */
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.country-flag {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.countryOption {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px; /* Add padding to the inner elements */
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;

  span {
    color: #86888d;
    font-size: 14px;
  }
}
.countryOption:hover {
  background-color: #f0f0f0; /* Change to your desired hover color */
}

.badge {
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 2px 8px;
  border-radius: 8px;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  color: grey;
}

.badge-icon {
  color: var(--primary);
}

.badge-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.badge-size {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.close-icon {
  font-size: 18px;
  color: var(--status-red);
  cursor: pointer;
}
</style>
